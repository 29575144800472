import { Email, Send, WhatsApp } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  TextField,
  Typography,
} from "@mui/material";
import strings from "../utilities/strings";
import { useEffect, useState } from "react";
import constants from "../utilities/constants";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

function ContactMe() {
  const [whatsAppSenderName, setWhatsAppSenderName] = useState("");
  const [whatsAppSenderEmail, setWhatsAppSenderEmail] = useState("");
  const [whatsAppMessage, setWhatsAppMessage] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [isWhatsAppButtonDisabled, setIsWhatsAppButtonDisabled] =
    useState(true);
  const [isEmailButtonDisabled, setIsEmailButtonDisabled] = useState(true);

  const clearWhatsAppFields = () => {
    setWhatsAppSenderName("");
    setWhatsAppSenderEmail("");
    setWhatsAppMessage("");
  };
  const sendWhatsAppMessage = () => {
    const phoneNumber = constants.phoneNumber;
    const message = `${strings.name}: ${whatsAppSenderName}\n${strings.email}: ${whatsAppSenderEmail}\n${strings.message}:\n${whatsAppMessage}`;
    window.open(
      `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
    clearWhatsAppFields();
  };

  useEffect(() => {
    setIsWhatsAppButtonDisabled(
      !whatsAppSenderName || !whatsAppSenderEmail || whatsAppMessage === ""
    );
  }, [whatsAppSenderName, whatsAppSenderEmail, whatsAppMessage]);

  const clearEmailFields = () => {
    setEmailSubject("");
    setEmailMessage("");
  };
  const sendEmailMessage = () => {
    const mailtoLink = `mailto:${constants.email}?subject=${encodeURIComponent(
      emailSubject
    )}&body=${encodeURIComponent(emailMessage)}`;
    window.open(mailtoLink);
    clearEmailFields();
  };

  useEffect(() => {
    setIsEmailButtonDisabled(!emailSubject || emailMessage === "");
  }, [emailSubject, emailMessage]);

  return (
    <Box sx={{ padding: "16px" }}>
      <Grid2 container gap={2} justifyContent="center">
        <Card
          variant="outlined"
          sx={{
            maxWidth: 345,
            backgroundColor: "secondary.secondary",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia
            alt="WhatsApp Icon"
            sx={{ textAlign: "center", mt: "16px" }}
          >
            <WhatsApp fontSize="large" color="secondary" />
            <Typography variant="h5" color="secondary" textAlign="center">
              WhatsApp
            </Typography>
          </CardMedia>
          <CardContent>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <TextField
                id="whats_app_sender_name"
                label={strings.yourName}
                variant="filled"
                color="secondary"
                size="small"
                type="text"
                value={whatsAppSenderName}
                inputProps={{
                  style: {
                    color: "#F0F0F0",
                  },
                }}
                onChange={(e) => {
                  setWhatsAppSenderName(e.target.value);
                }}
              />
              <TextField
                id="whats_app_sender_email"
                label={strings.yourEmail}
                variant="filled"
                color="secondary"
                size="small"
                type="email"
                value={whatsAppSenderEmail}
                inputProps={{
                  style: {
                    color: "#F0F0F0",
                  },
                }}
                onChange={(e) => {
                  setWhatsAppSenderEmail(e.target.value);
                }}
              />
              <TextField
                multiline
                rows={3}
                id="whats_app_sender_message"
                label={strings.yourMessage}
                variant="filled"
                color="secondary"
                size="small"
                type="text"
                value={whatsAppMessage}
                inputProps={{
                  style: {
                    color: "#F0F0F0",
                  },
                }}
                onChange={(e) => {
                  setWhatsAppMessage(e.target.value);
                }}
              />
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            {!isWhatsAppButtonDisabled ? (
              <Button
                size="small"
                variant="text"
                color="secondary"
                onClick={clearWhatsAppFields}
              >
                {strings.clear}
              </Button>
            ) : (
              ""
            )}
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={isWhatsAppButtonDisabled}
              endIcon={<Send />}
              onClick={sendWhatsAppMessage}
            >
              {strings.send}
            </Button>
          </CardActions>
        </Card>
        <Card
          variant="outlined"
          sx={{
            maxWidth: 345,
            backgroundColor: "secondary.secondary",
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <CardMedia alt="Email Icon" sx={{ textAlign: "center", mt: "16px" }}>
            <Email fontSize="large" color="secondary" />
            <Typography variant="h5" color="secondary" textAlign="center">
              Email
            </Typography>
          </CardMedia>
          <CardContent>
            <Box display="flex" flexDirection="column" gap={0.5}>
              <TextField
                id="email_subject"
                label={strings.subject}
                variant="filled"
                color="secondary"
                size="small"
                type="text"
                value={emailSubject}
                inputProps={{
                  style: {
                    color: "#F0F0F0",
                  },
                }}
                onChange={(e) => {
                  setEmailSubject(e.target.value);
                }}
              />
              <TextField
                multiline
                rows={3}
                id="email_message"
                label={strings.yourMessage}
                variant="filled"
                color="secondary"
                size="small"
                type="text"
                value={emailMessage}
                inputProps={{
                  style: {
                    color: "#F0F0F0",
                  },
                }}
                onChange={(e) => {
                  setEmailMessage(e.target.value);
                }}
              />
            </Box>
          </CardContent>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            {!isEmailButtonDisabled ? (
              <Button
                size="small"
                variant="text"
                color="secondary"
                onClick={clearEmailFields}
              >
                {strings.clear}
              </Button>
            ) : (
              ""
            )}
            <Button
              size="small"
              variant="contained"
              color="primary"
              disabled={isEmailButtonDisabled}
              endIcon={<Send />}
              onClick={sendEmailMessage}
            >
              {strings.send}
            </Button>
          </CardActions>
        </Card>
      </Grid2>
    </Box>
  );
}

export default ContactMe;
