const constants = {
  phoneNumber: "6289637504050",
  email: "ridhwanaf8111@gmail.com",
  socialMediaUrl: {
    playStoreUrl:
      "https://play.google.com/store/apps/dev?id=4651864067675716886",
    instagramUrl: "https://www.instagram.com/ridhwanafzn",
    facebookUrl: "https://www.facebook.com/ridhwanafzn",
    linkedInUrl: "https://www.linkedin.com/in/ridhwan-anwar-fauzan-4075b216a/",
    gitHubUrl: "https://github.com/RidhwanAF",
    xUrl: "https://x.com/Ridhwanafzn",
    whatsAppUrl: "https://wa.me/6289637504050",
  },
  skillLevelValue: {
    android: 90,
    jetpackCompose: 80,
    reactJs: 65,
    gitHub: 95,
    figma: 85,
    nodeJs: 40,
    deviceRepair: 72,
    kotlin: 90,
    javaScript: 50,
    typeScript: 50,
    java: 35,
    indonesian: 99,
    english: 80,
    japanese: 25,
  },
};

export default constants;
