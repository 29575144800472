import TravelokaProject from "../images/traveloka-project.png";
import SmartMotorcycleAssistantProject from "../images/smart-motorcycle-assistant-project.png";
import BriLifeProject from "../images/bri-life-project.png";
import BestAnimeToWatchProject from "../images/best-anime-to-watch-project.png";
import MyAlQuranProject from "../images/my-alquran-android-project.png";
import PopupAnythingProject from "../images/popupanything-android-project.png";

const itemProjectData = [
  {
    img: PopupAnythingProject,
    title: "Popup Anything",
    desc: "A fun and interactive way to customize your connected device experience. Popup Anything makes those Bluetooth connections a little more delightful. Whenever you connect to a chosen Bluetooth device, a charming popup animation will appear on your screen, complete with the device name and even pictures you've chosen!",
    gitHubUrl: "",
    publishedUrl:
      "https://play.google.com/store/apps/details?id=com.raf.popupanything",
  },
  {
    img: MyAlQuranProject,
    title: "My AL Qur'an",
    desc: "Experience the Holy Quran like never before with a native Android application built on Jetpack Compose, offering premium features, customizable settings, and an exceptional user interface with captivating animations.",
    gitHubUrl: "",
    publishedUrl:
      "https://play.google.com/store/apps/details?id=com.heavenly.myalquran",
  },
  {
    img: SmartMotorcycleAssistantProject,
    title: "Smart Motorcycle Assistant",
    desc: "Smart assistant to help with motorcycle needs.",
    gitHubUrl: "",
    publishedUrl:
      "https://play.google.com/store/apps/details?id=com.raf.smartmotorcycleassistant&pli=1",
  },
  {
    img: BriLifeProject,
    title: "BRI Life - MyLife",
    desc: "BRI Life insurance company",
    gitHubUrl: "",
    publishedUrl: "",
  },
  {
    img: TravelokaProject,
    title: "Traveloka Hotel Ranking",
    desc: "Hotel recommendations that match user behavior.",
    gitHubUrl: "https://github.com/Traveloka-Hotel-Ranking",
    publishedUrl: "",
  },
  {
    img: BestAnimeToWatchProject,
    title: "The Best Anime To Watch",
    desc: "My first Android app developed using Jetpack Compose",
    gitHubUrl:
      "https://github.com/RidhwanAF/BestAnimeToWatch_MaterialDesign_JetpackCompose",
    publishedUrl: "",
  },
];

export default itemProjectData;
