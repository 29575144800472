import {
  Avatar,
  Box,
  Container,
  Fab,
  IconButton,
  Tab,
  Tabs,
  ThemeProvider,
  Typography,
  createTheme,
  responsiveFontSizes,
} from "@mui/material";
import strings from "./utilities/strings";
import "./App.scss";
import "./App768.scss";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import MyPicture from "./images/my-picture-border.png";
import { useState } from "react";
import {
  Facebook,
  GitHub,
  Instagram,
  LinkedIn,
  Shop,
  X,
  WhatsApp,
  Download,
} from "@mui/icons-material";
import { keyframes } from '@mui/system';
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import Skills from "./pages/Skills";
import ContactMe from "./pages/ContactMe";
import Project from "./pages/Project";
import constants from "./utilities/constants";
import { PhotoProvider, PhotoView } from "react-photo-view";
import "react-photo-view/dist/react-photo-view.css";
import Service from "./pages/Service";
import { useEffect } from "react";
import CvPdf from "../src/MyCV.pdf"

let theme = createTheme({
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "h2",
          subtitle2: "h2",
          body1: "span",
          body2: "span",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#41444B",
    },
    secondary: {
      main: "#CABFAB",
      secondary: "#CABFAB25",
    },
    error: {
      main: "#BA1A1A",
    },
    background: {
      default: "#41444B",
    },
    text: {
      primary: "#191C1E",
      secondary: "#F0F0F0",
    },
  },
  typography: {
    fontFamily: ["Roboto, sans-serif"].join(","),
  },
});

theme = responsiveFontSizes(theme);

// Animation Init
const initAnimation = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

// Animation Init Tab
const tabInitAnimation = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

function App() {
  const [tabValue, setTabValue] = useState(0);

  // Tab Content Animation
  const [isTabContentLoaded, setIsTabContentLoaded] = useState(false);

  const handleChange = (event, newValue) => {
    setIsTabContentLoaded(false)
    setTabValue(newValue);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTabContentLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, [tabValue]);

  // Tab Content
  const tabContents = [
    {
      title: strings.tabsTitleAboutMe,
      content: strings.aboutMe("body", "text.secondary", "center", "16px"),
    },
    { title: strings.tabsTitleSkills, content: <Skills /> },
    { title: strings.tabsTitleProjects, content: <Project /> },
    { title: strings.tabsTitleServices, content: <Service /> },
    { title: strings.tabsTitleContactMe, content: <ContactMe /> },
  ];

  const socialMediaButton = (icon, url) => {
    return (
      <IconButton
        color="secondary"
        size="large"
        sx={{
          transition: "transform 0.3s ease",
          "&:hover": {
            transform: "scale(0.85)",
          },
        }}
        onClick={() => window.open(url, "_blank")}
      >
        {icon}
      </IconButton>
    );
  };

  // Init Animation
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timer);
  }, []);

  // FAB Download CV
  const onFabClicked = () => {
    const link = document.createElement('a')
    link.href = CvPdf;
    link.download = 'CV-RidhwanAnwarFauzan.pdf'
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <ResponsiveAppBar />
        <div className="content">
          <Container maxWidth="xl">
            <div className="bio">
              <PhotoProvider speed={() => 500} maskOpacity={0.9}>
                <div className="foo">
                  <PhotoView key={MyPicture} src={MyPicture}>
                    <Box
                      width="250px"
                      height="250px"
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      position="relative"
                      sx={{
                        opacity: 0,
                        transition: "transform 0.3s ease",
                        "&:hover": {
                          transform: "scale(0.9)",
                        },
                        animation: isLoaded ? `${initAnimation} 0.5s forwards` : 'none',
                      }}
                    >
                      <Box
                        width="175px"
                        height="175px"
                        sx={{
                          position: "absolute",
                          borderTop: "16px solid #CABFAB",
                          borderLeft: "16px solid #CABFAB",
                          borderRight: "16px solid #CABFAB",
                          borderBottom: "16px solid transparent",
                          borderRadius: "50%",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          mt: "13px",
                        }}
                      />
                      <Avatar
                        alt="Profile-Picture"
                        key={MyPicture}
                        src={MyPicture}
                        sx={{
                          width: "250px",
                          height: "250px",
                          mb: "44px",
                        }}
                      />
                      <Box
                        width="175px"
                        height="175px"
                        sx={{
                          position: "absolute",
                          borderBottom: "16px solid #CABFAB",
                          borderLeft: "16px solid #CABFAB",
                          borderRight: "16px solid #CABFAB",
                          borderTop: "16px solid transparent",
                          borderRadius: "50%",
                          bottom: "0",
                          left: "50%",
                          transform: "translateX(-50%)",
                          mb: "8px",
                        }}
                      />
                    </Box>
                  </PhotoView>
                </div>
              </PhotoProvider>
              <Box color="text.secondary"
                sx={{
                  opacity: 0,
                  animation: isLoaded ? `${initAnimation} 0.5s forwards` : 'none',
                }}
              >
                <Typography variant="h5">{strings.introduction}</Typography>
                <Typography variant="h2" fontWeight="bold" color="secondary">
                  {strings.myName}
                </Typography>
                <Typography variant="h6" color="#B4B4B3">
                  {strings.bio.toUpperCase()}
                </Typography>
                <Grid2 container justifyContent="center" sx={{ flexGrow: 1 }}>
                  {socialMediaButton(
                    <Instagram fontSize="medium" />,
                    constants.socialMediaUrl.instagramUrl
                  )}
                  {socialMediaButton(
                    <Facebook fontSize="medium" />,
                    constants.socialMediaUrl.facebookUrl
                  )}
                  {socialMediaButton(
                    <WhatsApp fontSize="medium" />,
                    constants.socialMediaUrl.whatsAppUrl
                  )}
                  {socialMediaButton(
                    <X fontSize="medium" />,
                    constants.socialMediaUrl.xUrl
                  )}
                  {socialMediaButton(
                    <LinkedIn fontSize="medium" />,
                    constants.socialMediaUrl.linkedInUrl
                  )}
                  {socialMediaButton(
                    <GitHub fontSize="medium" />,
                    constants.socialMediaUrl.gitHubUrl
                  )}
                  {socialMediaButton(
                    <Shop fontSize="medium" />,
                    constants.socialMediaUrl.playStoreUrl
                  )}
                </Grid2>
              </Box>
            </div>
          </Container>
          <Box
            sx={{
              width: "100vw",
              borderBottom: 2,
              borderColor: "divider",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="scrollable auto tabs"
              sx={{
                opacity: 0,
                transition: "transform 0.3s ease",
                animation: isLoaded ? `${initAnimation} 0.5s forwards` : 'none',
              }}
            >
              {tabContents.map((item, index) => (
                <Tab key={index} label={item.title}
                  sx={{
                    transition: "transform 0.3s ease, color 0.3s ease",
                    "&:hover": {
                      transform: "scale(0.9)",
                    },
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <Box
            sx={{
              opacity: 0,
              transition: "transform 0.2s ease",
              animation: isTabContentLoaded ? `${tabInitAnimation} 0.5s forwards` : 'none',
            }}
          >
            {tabContents[tabValue].content}
          </Box>
        </div>
      </div>
      <Fab variant="extended"
        onClick={onFabClicked}
        sx={{
          position: "sticky",
          bottom: 16,
          left: '50%',
          margin: "16px",
          transform: 'translateX(-50%)',
          zIndex: 1000,
        }}
      >
        <Download sx={{ mr: 1 }} />
        <Box>
          <Typography component="div" fontWeight="bold" fontSize={12}>
            {strings.curriculumVitae || "Curriculum Vitae"}
          </Typography>
          <Typography component="div" fontWeight="light" fontSize={10}>
            {strings.lastUpdated}: {strings.date("03/03/2024")}
          </Typography>
        </Box>
      </Fab>
      <footer>
        <Box
          sx={{
            backgroundColor: "secondary.secondary",
            p: "16px",
            textAlign: "center",
          }}
        >
          <Typography fontSize="12px" color="text.secondary">
            Copyright © 2023 ridhwanafzn All Rights reserved.
          </Typography>
        </Box>
      </footer>
    </ThemeProvider>
  );
}

export default App;
