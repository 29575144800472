import { Box, Typography } from "@mui/material";
import utils from "./utils";

let lang = localStorage.getItem("language")
  ? localStorage.getItem("language")
  : "en";

const birthDay = "2001-11-08";
const startAndroid = "2021-12-08";

let strings = {
  introduction: "Hello, it's me",
  myName: "Ridhwan.",
  bio: "Android Developer",
  tabsTitleAboutMe: "About Me",
  tabsTitleSkills: "Skills",
  tabsTitleProjects: "Projects",
  tabsTitleServices: "Services",
  tabsTitleContactMe: "Contact Me",
  aboutMe: (variant, color, textAlign, padding) => {
    const age = utils.calculateAge(birthDay);
    const androidExperience = utils.calculateAge(startAndroid);
    return (
      <Box p={padding}>
        <Typography
          variant={variant}
          color={color}
          textAlign={textAlign}
        >
          <Typography color="secondary" fontWeight="bold">
            Ridhwan Anwar Fauzan | {age.yearsOnly} years old | Indonesia
          </Typography>
          <br />
          <br />
          An Android developer with {androidExperience.age} years of experience,
          holding a degree from Indonesian Computer University with a GPA of 3.47
          (Excellent). Passionate about technology and committed to delivering
          innovative solutions. I have also obtained certification from the
          National Professional Certification Board (BNSP), confirming my
          proficiency in Android development. Additionally, I have participated in
          the Bangkit Academy program for 6 months, a joint initiative by Google,
          GoTo, and Traveloka, where I focused on software development with an
          emphasis on Android applications and softskills.
          <br />
          <br />
          Throughout my career, I have been actively involved in creating Android
          and web applications. I am also proficient in both Indonesian and
          English. My preferred Android development environment is native using
          Kotlin, XML, Jetpack Compose, MVVM pattern, Android Lifecycle, Room, API
          Integration, etc. I am looking for a challenging and rewarding
          opportunity as an Android developer where I can use my skills and
          experience to build innovative apps.
          <br />
          <br />
          Outside of it, I am Calisthenics practitioner and coach, and I have been
          fixing computers, laptops, and phones since 2017-2022 for part time.
        </Typography>
      </Box>
    );
  },
  skillLevel: ["Beginner", "Intermediate", "Advanced", "Expert"],
  programmingLanguages: "Programming Languages",
  languages: "Languages",
  level: "Level",
  yourName: "Your Name",
  yourEmail: "Your Email",
  yourMessage: "Your Message",
  send: "Send",
  clear: "Clear",
  name: "Name",
  email: "Email",
  message: "Message",
  subject: "Subject",
  publishedApps: "Published Apps",
  curriculumVitae: "Curriculum Vitae",
  lastUpdated: "last updated",
  date: (value) => {
    return utils.formatDate(value, "en-US")
  }
};

if (lang === "in") {
  strings = {
    introduction: "Halo, ini saya",
    myName: "Ridhwan.",
    bio: "Android Developer",
    tabsTitleAboutMe: "Tentang Saya",
    tabsTitleSkills: "Kemampuan",
    tabsTitleProjects: "Projek",
    tabsTitleServices: "Layanan",
    tabsTitleContactMe: "Kontak Saya",
    aboutMe: (variant, color, textAlign, padding) => {
      const age = utils.calculateAge(birthDay);
      const androidExperience = utils.calculateAge(startAndroid);
      return (
        <Box p={padding}>
          <Typography
            variant={variant}
            color={color}
            textAlign={textAlign}
          >
            <Typography color="secondary" fontWeight="bold">
              Ridhwan Anwar Fauzan | {age.yearsOnly} Tahun | Indonesia
            </Typography>
            <br />
            <br />
            Seorang pengembang Android dengan pengalaman {
              androidExperience.age
            }{" "}
            tahun, lulus dari Universitas Komputer Indonesia dengan IPK 3.47
            (Sangat Memuaskan). Dengan passion dalam bidang teknologi dan
            berkomitmen untuk memberikan solusi inovatif. Saya juga telah
            memperoleh sertifikasi dari Badan Nasional Sertifikasi Profesi (BNSP),
            yang mengkonfirmasi kemampuan saya dalam pengembangan Android. Selain
            itu, saya telah berpartisipasi dalam program Akademi Bangkit selama 6
            bulan, inisiatif bersama dari Google, GoTo, dan Traveloka, di mana
            saya fokus pada pengembangan perangkat lunak dengan penekanan pada
            aplikasi Android dan softskills.
            <br />
            <br />
            Sepanjang karier saya, saya aktif terlibat dalam pembuatan aplikasi
            Android dan web. Saya dapat berbicara dalam bahasa Indonesia dan
            Inggris. Dalam lingkungan pengembangan Android saya menggunakan native
            Kotlin, XML, Jetpack Compose, pola MVVM, Android Lifecycle, Room,
            Integrasi API, dan lain-lain. Saya sedang mencari peluang yang
            menantang dan memuaskan sebagai pengembang Android di mana saya dapat
            menggunakan keterampilan dan pengalaman saya untuk membangun aplikasi
            atau teknologi inovatif.
            <br />
            <br />
            Di luar itu, saya merupakan praktisi dan pelatih Calisthenics. Saya
            telah memperbaiki komputer, laptop, dan ponsel sejak 2017-2022 sebagai
            paruh waktu.
          </Typography>
        </Box>
      );
    },
    skillLevel: ["Pemula", "Menengah", "Lanjutan", "Ahli"],
    programmingLanguages: "Bahasa Pemrograman",
    languages: "Bahasa",
    level: "Tingkat",
    yourName: "Nama Anda",
    yourEmail: "Email Anda",
    yourMessage: "Pesan Anda",
    send: "Kirim",
    clear: "Bersihkan",
    name: "Nama",
    email: "Email",
    message: "Pesan",
    subject: "Subjek",
    publishedApps: "Aplikasi Terpublikasi",
    curriculumVitae: "Daftar Riwayat Hidup",
    lastUpdated: "terakhir diperbarui",
    date: (value) => {
      return utils.formatDate(value, "id-ID")
    }
  };
} else if (lang == "jp") {
  strings = {
    introduction: "こんにちは、私です",
    myName: "リドワン",
    bio: "Android開発者",
    tabsTitleAboutMe: "私について",
    tabsTitleSkills: "スキル",
    tabsTitleProjects: "プロジェクト",
    tabsTitleServices: "サービス",
    tabsTitleContactMe: "お問い合わせ",
    aboutMe: (variant, color, textAlign, padding) => {
      const age = utils.calculateAge(birthDay);
      const androidExperience = utils.calculateAge(startAndroid);
      return (
        <Box p={padding}>
          <Typography
            variant={variant}
            color={color}
            textAlign={textAlign}
          >
            <Typography color="secondary" fontWeight="bold">
              リドワン・アンワル・ファウザン | {age.yearsOnly}歳 | インドネシア
            </Typography>
            <br />
            <br />
            Android開発者としての経験は{
              androidExperience.age
            }年、インドネシアコンピュータ大学をGPA3.47（優秀）で卒業しました。技術への情熱を持ち、革新的なソリューションを提供することに専念しています。また、Android開発のプロフェッショナルとしてのスキルを確認するための国家職業資格認定委員会（BNSP）からの認定を取得しています。さらに、Google、GoTo、Travelokaの共同イニシアチブであるBangkitアカデミープログラムに6か月間参加し、Androidアプリケーションとソフトスキルに重点を置いたソフトウェア開発に取り組みました。
            <br />
            <br />
            私のキャリアを通じて、AndroidおよびWebアプリケーションの作成に積極的に関わっています。また、インドネシア語と英語の両方に堪能です。私が好むAndroid開発環境は、Kotlin、XML、Jetpack Compose、MVVMパターン、Android Lifecycle、Room、API統合などを使用したネイティブ開発です。私のスキルと経験を活かして革新的なアプリを構築するための挑戦的でやりがいのあるAndroid開発者の機会を探しています。
            <br />
            <br />
            それ以外にも、カリステニクスの実践者兼コーチであり、2017年から2022年までの間、コンピュータ、ラップトップ、携帯電話の修理をパートタイムで行っていました。
          </Typography>
        </Box>
      );
    },
    skillLevel: ["初心者", "中級者", "上級者", "専門家"],
    programmingLanguages: "プログラミング言語",
    languages: "言語",
    level: "レベル",
    yourName: "あなたの名前",
    yourEmail: "あなたのメール",
    yourMessage: "あなたのメッセージ",
    send: "送信",
    clear: "クリア",
    name: "名前",
    email: "メール",
    message: "メッセージ",
    subject: "件名",
    publishedApps: "公開されたアプリ",
    curriculumVitae: "履歴書",
    lastUpdated: "最終更新日",
    date: (value) => {
      return utils.formatDate(value, "ja-JP")
    }
  };
}

export default strings;
