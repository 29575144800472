import RogPhone3 from "../images/rogphone3.jpg";
import Pixel4 from "../images/pixel4.jpg";
import SamsungS20Fe from "../images/samsung-s20fe.jpg";
import Realme8 from "../images/realme8.jpg";
import ROGGL553VD from "../images/rog-gl553vd.jpg";

const itemServiceData = [
  {
    id: 1,
    img: RogPhone3,
    title: "Asus ROG Phone 3",
    descEn: "Charging port resurrection, battery replacment, thermal paste replacement.",
    descId: "Perbaikan port pengisian daya, penggantian baterai, penggantian pasta termal.",
  },
  {
    id: 2,
    img: Pixel4,
    title: "Google Pixel 4",
    descEn: "Battery & screen replacemnt",
    descId: "Penggantian baterai & layar",
  },
  {
    id: 3,
    img: SamsungS20Fe,
    title: "Samsung S20 FE",
    descEn: "Water damage resurrection",
    descId: "Perbaikan kerusakan air",
  },
  {
    id: 4,
    img: Realme8,
    title: "Realme 8 4G",
    descEn: "Water damage resurrection & Screen replacement",
    descId: "Perbaikan kerusakan air & penggantian Layar",
  },
  {
    id: 5,
    img: ROGGL553VD,
    title: "Asus ROG GL553VD",
    descEn: "RAM upgrade & Thermal paste changing",
    descId: "Penambahan RAM & penggantian pasta termal",
  },
];

export default itemServiceData;
